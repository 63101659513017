html,
body {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Saira", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f7ff;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: "Saira", sans-serif !important;
}

#root {
  height: 100%;
}

/* MY STYLE */

/* .sc-eCApnc.eweaTq {
  background-color: #2C4773;
  padding: 0px;
  margin: 0px;
} */
body {
  width: 100%;
  overflow-x: hidden;
  background-color: #f0f7ff;
}
.hd_menu {
  margin-left: -45px;
}
ul {
  padding: 0px;
  margin: 0px;
}
.hd_menu ul li {
  display: inline-block;
  width: 25%;
}
.hd_menu ul li a.active,
.hd_menu ul li a:hover {
  background: rgba(0, 0, 0, 0.34);
  font-weight: 500;
}
.hd_menu ul li a {
  color: #fff;
  font-size: 14.6px;
  font-weight: 400;
  padding: 30px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 100%;
}
.hd_menu ul li button {
  margin: 0;
  color: #fff;
  font-size: 14.6px;
  font-weight: 400;
  padding: 30px 0px;
  text-align: center;
  text-decoration: none;
  text-transform: none !important;
  display: inline-block;
  width: 100%;
}
.hd_menu ul li button.active,
.hd_menu ul li button:hover {
  background: rgba(0, 0, 0, 0.34);
  font-weight: 500;
}
.review-menu-item{
  display: flex;
  flex-direction: column;
}
.container {
  width: 99%;
  max-width: 99%;
  margin: 0 auto;
  padding: 0px;
}
.logo_hd {
  padding: 9px 4px;
}
.row.hd {
  width: 100%;
}
ul {
  padding: 0px;
  margin-bottom: 0rem !important;
}
ul.itm_icon {
  /* padding: 25px 0px 23.5px 0px; */
  padding: 25px 0px 19.5px 0px;
}
ul.itm_icon li {
  display: inline-block;
  padding: 0px 6px;
  vertical-align: middle;
}
ul.itm_icon li span {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  padding: 0px 8px;
}
ul.itm_icon li span img {
  margin-right: 3px;
}

h4.hd_box {
  color: #2c4773;
  font-weight: 600;
  font-size: 17.5px;
  padding: 12px 3px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.wht_box {
  background: #ffffff;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 6%);
  border-radius: 10px;
  padding: 9px 26px 30px 26px;
  width: 92%;
  /* float: left; */
  margin: 0 auto;
}
.sc-ckTSus.eVOaHm {
  flex-basis: 32%;
  background: #f0f7ff;
  height: auto;
  overflow: auto;
}
.sc-gGLxEB.bRWmvD {
  background: #f0f7ff;
  padding: 25px 10px;
}
.sc-fbIWvP.cemEBN {
  flex-basis: 68%;
}
.wht_box input,
.wht_box .MuiSelect-outlined.MuiSelect-outlined {
  display: block;
  width: 100%;
  padding: 11.8px;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Saira", sans-serif !important;
}
.wht_box .MuiSelect-outlined.MuiSelect-outlined {
  padding: 9.7px;
}
.form_item_fr {
  margin-top: 18px;
}
fieldset.PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}
.wht_box label {
  font-size: 14px;
  font-weight: 400;
}

.form_item_fr.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  text-align: left;
  margin-top: 4px;
}
.MuiFormControl-root {
  min-width: 100% !important;
}
.btn-group.dropup.on {
  width: 100%;
  /* margin-right: 15px; */
  margin-top: 14px;
}
.btn-group.dropup.tw {
  width: 48%;
  margin-right: 15px;
  margin-top: 14px;
}
.form_item_fr.trd {
  width: 48%;
}
.btn-group.dropup.tw label {
  display: none;
}
.btn_box {
  margin-top: 26px;
}
.btn_box .MuiButton-containedPrimary {
  background-color: #2c4773;
  padding: 11px 40px 8px 40px;
  border-radius: 4px;
  color: #fff;
  font-size: 14.3px;
  font-weight: 500;
  text-decoration: none;
  box-shadow: none;
  font-family: "Saira", sans-serif !important;
}
.MuiOutlinedInput-root:hover {
  outline: none;
}

.cNWLpY {
  height: auto !important;
}

.MuiListItemText-multiline {
  padding: 15px 13px;
  margin: 0px 16px;
  border-bottom: 1px solid #a1a1a1;
  cursor: pointer;
}
.MuiListItem-root {
  padding: 0px !important;
}
.MuiListItemText-root,
.MuiListItemText-multiline {
  margin: 0px 0px 0px 5px !important;
  margin-bottom: 4px;
  padding: 10px 13px;
  border-bottom: 1px solid #a1a1a1;
}

/* RIGHT FORM */

.bBMCPV {
  width: 97% !important;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 8%);
  border-radius: 10px;
}
.MuiAppBar-colorPrimary,
.MuiPaper-root,
.MuiPaper-elevation4 {
  background-color: white !important;
  padding: 8px 10px;
  box-shadow: none !important;
  color: #000;
}
.ad_tabn {
  width: 19.5%;
  margin: 0 auto;
}
.ad_tabn .MuiTab-textColorInherit,
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
  width: auto;
  margin: 0 auto;
  font-size: 12.9px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  color: #111824;
  padding: 13px 2px;
  overflow: hidden;
  background-color: #e4f1ff;
  border-radius: 3px;
  border: none;
  opacity: 0.8;
  font-family: "Saira", sans-serif !important;
}

.wid_dep
  button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected {
  background-color: #2c4773 !important;
  color: #fff !important;
  opacity: 1;
  width: auto;
  min-width: 140px;
  padding: 0px 4px;
}
.wor.mt-3 {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  font-family: "Saira", sans-serif !important;
}
.form_group.rg {
  width: 39%;
}
.form_group.lf {
  width: 57%;
}
.form_group.details{
  width: 100%;
}
.wor input,
.wor select {
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0px;
  padding: 9.3px;
  font-size: 13.8px;
  font-family: "Saira", sans-serif !important;
}
.twnf,
.form_group.twnf {
  width: 22%;
}
.wor.trd .form_group.fif,
.cont_item {
  width: 48%;
}
.MuiInputLabel-animated {
  font-family: "Saira", sans-serif !important;
  font-size: 13.8px;
}
.set1_tab {
  padding: 6px 13px;
}
.MuiFormLabel-root {
  color: rgb(0 0 0 / 68%);
}
.set1_tab label {
  font-size: 15.6px;
}
h3.hd_tg {
  font-weight: 500;
  font-size: 18.5px;
  color: #000;
  margin-bottom: 0px;
}
.set2_tab,
.set3_tab {
  padding: 40px 13px 0px 13px;
}
fieldset.PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline {
  border: none;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 12.5px 4px !important;
}
.form_group.fif
  .MuiAutocomplete-hasPopupIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 0px;
}
.form_group.fif .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px;
}
.form_group.fif {
  width: 48%;
}
.wor.cont_st {
  align-items: flex-start;
}
.wor.cont_clo {
  justify-content: flex-start;
}
/* .MuiInputBase-inputMultiline */
.form_group.fif textarea {
  height: 95px !important;
}
.cont_set p {
  font-weight: 400;
  font-size: 15px;
  color: #000;
  line-height: 14px;
  font-family: "Saira", sans-serif !important;
}
.cont_set {
  margin-top: 26px;
  padding-right: 21px;
}
.PrivateSwitchBase-root-11 {
  padding: 0px 5px;
}
/* .MuiFormControlLabel-labelPlacementStart {
  margin-left: 3px;
  margin-right: 16px;
  flex-direction: row-reverse;
} */
.btn_box.rg {
  text-align: right;
  margin-top: 21px;
}
.btn_box.rg button {
  width: 130px;
  margin-right: 13px;
  text-align: center;
  font-size: 14.3px;
  border-radius: 4px;
}
/* button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.Mui-disabled.Mui-disabled */
.btn_box.rg button {
  background-color: #2c4773;
  color: #fff !important;
  padding: 10px 30px 7px 30px;
}
ul.MuiList-root.MuiMenu-list.MuiList-padding li {
  font-size: 13.8px;
  padding: 5px 5px !important;
}
ul.MuiList-root.MuiMenu-list.MuiList-padding {
  background: #fff;
}

.dwWiSJ {
  position: relative;
  margin-top: 14px;
}

.btn_box.delt button {
  margin-top: 8px;
  margin-left: 13px;
  width: 150px;
  margin-right: 13px;
  text-align: center;
  font-size: 14.3px;
  border-radius: 4px;
  padding: 12px 10px 9px 10px;
  background-color: #2c4773;
}
.btn_box.delt.mgmt-company button {
  margin-top: -30px;
  width: 310px !important;
}
.btn_box.delt_sub button {
  margin-top: 8px;
  margin-left: 13px;
  width: 180px;
  margin-right: 13px;
  text-align: center;
  font-size: 14.3px;
  border-radius: 4px;
  padding: 12px 10px 9px 10px;
  background-color: #2c4773;
}
.jqeCAT {
  padding: 10px 10px 30px 10px !important;
  width: 100%;
}
.dZPMOA {
  position: relative;
  margin-top: 20px;
}
.btn_box.rg {
  margin-top: 34px;
}
.btn_box.rg button.can {
  color: #000;
  background-color: #f1f1f1;
}
/* .MuiButton-root.Mui-disabled
{
  color:#323232 !important;
  padding: 10px 40px 7px 40px;
  background-color: #e3e3e3;
} */
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 0px !important;
}
.form_group.lf.ls .MuiInputLabel-outlined.MuiInputLabel-marginDense {
  transform: inherit !important;
  margin-top: -9px;
  font-size: 13px;
}
.btn_box.dis .MuiIcon-root {
  margin-top: -3px;
}
.btn_box.dis {
  margin-top: 12px;
}
.wid_dep {
  background: #fff;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 6%);
  border-radius: 10px;
  width: 97.5%;
  margin: 0 auto;
  padding: 12px 3px 35px 3px !important;
}
.wte_down {
  background: #fff;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 6%);
  border-radius: 10px;
  /* margin-top: 20px; */
}
.wte_btn {
  margin-top: 20px;
}
ul.MuiList-root.sc-jQAxuV.knmVij.MuiList-padding {
  background: #fff;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 6%);
  border-radius: 10px;
  width: 92%;
  margin: 0 auto;
}
.dLVbiE {
  background: transparent;
  background-color: transparent;
}
ul.MuiList-root.sc-jQAxuV.knmVij.MuiList-padding {
  background: #ffffff;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 6%);
  border-radius: 10px;
  padding: 9px 26px 45px 26px;
  width: 92%;
  margin: 0 auto;
  /* float: left; */
  margin-top: 20px;
  padding: 10px 0px;
  max-height: 500px;
  overflow: auto;
}
.MuiListItem-gutters {
  padding: 0px;
}
/* span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock*/
.min_li span {
  font-size: 15px;
  color: #213b65;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  padding-bottom: 5px;
  font-family: "Saira", sans-serif !important;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* p.MuiTypography-root.MuiListItemText-secondary.MuiTypography-body2
.MuiTypography-colorTextSecondary.MuiTypography-displayBlock */
.min_li p {
  color: #2c2c2c;
  font-weight: 400;
  font-size: 13.5px;
}
.MuiTypography-body2 {
  font-family: "Saira", sans-serif !important;
}
.PrivateSwitchBase-root-11 {
  padding: 6px;
}
/* .fZkXZG {
  padding: 12px 12px 35px 12px !important;
} */
/* .MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px;
  margin-right: 11px;
} */
.tab_dev {
  width: 100%;
  margin-top: 14px;
  position: relative;
  z-index: 555;
}

/* third tab */

/* .tab_de .MuiDataGrid-root .MuiDataGrid-columnsContainer,
.MuiDataGrid-columnsContainer { */
  /* position: inherit !important; */
  /* overflow: auto !important;
  min-height: 75px !important; */
/* } */
.MuiDataGrid-root .MuiDataGrid-footer {
  display: flex;
  /* min-height: 112px !important; */
  position: relative;
}
.MuiTablePagination-root:last-child {
  padding: 0;
  position: absolute;
  bottom: 0px;
  right: 10px;
}
.flx_arg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 13px 0px 13px;
}
.dif_one{
  width: 37.5%;
}
.dif_two {
  width: 13.5%;
}
.dif_thre,
.dif_four {
  width: 8%;
  text-align: center;
}
.flx_arg input,
.flx_arg .MuiSelect-outlined.MuiSelect-outlined {
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0px;
  padding: 12.3px;
  font-size: 13.8px;
  font-family: "Saira", sans-serif !important;
  margin-top: 10px;
}
.dif_thre button {
  background: #2c4773;
  padding: 10px;
  width: auto;
  min-width: auto !important;
}
.dif_thre .material-icons {
  color: #fff;
}
.dif_thre button:hover{
  background: rgb(85, 112, 155);
}
.dif_thre .material-icons {
  color: #fff;
}
.cre_btn
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  background-color: #2c4773;
  padding: 10px 40px 7px 40px;
  border-radius: 4px;
  color: #fff;
  font-size: 14.3px;
  font-weight: 500;
  text-decoration: none;
  box-shadow: none;
  font-family: "Saira", sans-serif !important;
  width: 100%;
}
.cre_btn
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary:disabled {
  background-color: #e0e0e0;
  color: #adadad;
}
.cre_btn {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
.dif_two .MuiInputLabel-outlined.MuiInputLabel-marginDense,
.dif_one .MuiInputLabel-outlined.MuiInputLabel-marginDense {
  transform: inherit !important;
}
.wor.cont_st1 {
  align-items: flex-end;
}
.btn_box.rg
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textSecondary {
  color: #323232 !important;
  padding: 10px 40px 7px 40px;
  background-color: #e3e3e3;
}

.MuiDataGrid-columnsContainer {
  max-height: auto !important;
}

.set4_tab {
  padding: 40px 13px 0px 13px;
}

/* .MuiTab-root {
  min-width: auto !important;
} */
.MuiTabs-flexContainer {
  display: flex;
  background: #eaf1f7;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  background: #fff !important;
  width: 50%;
}
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiPaper-elevation24.MuiPaper-rounded {
  background: #fff !important;
  width: 70%;
}
/* button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Mui-disabled.Mui-disabled {
  background: #2C4773;
  color:#fff !important;
} */
.sc-cBoqAE.gmgyYd {
  display: block;
}

/* NEW STYLE */

.bg_blue {
  background-color: #2c4773;
}
.sec_filt {
  background: #ffffff;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 6%);
  border-radius: 10px;
  /* padding: 9px 26px 30px 26px; */
  max-height: 1000px;
  overflow-y: auto;
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
}

/* --------- */
.MuiTablePagination-root:last-child {
  padding: 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
  top: auto;
}
.MuiDataGrid-root .MuiDataGrid-main {
  min-height: 180px;
}
/* .MuiDataGrid-root .MuiDataGrid-window {
  z-index: -1;
} */
.set4_tab_ne {
  padding: 0px 13px 30px 13px;
}
.wht_box fieldset.jss1.MuiOutlinedInput-notchedOutline,
.wht_box .MuiOutlinedInput-notchedOutline {
  border: transparent !important;
}
/* .div_row .MuiDataGrid-window {
  top: 75px !important;
} */

.div_row .MuiDataGrid-dataContainer.data-container {
  margin-top: 15px !important;
}
span.PrivateTabIndicator-root-5.PrivateTabIndicator-colorSecondary-7.MuiTabs-indicator {
  width: auto;
  min-width: 140px;
  display: none;
}
.form_group.itm input,
.form_group.itm select,
.form_group.itm input.MuiSelect-nativeInput,
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {
  /* border: none; */
  border-bottom: 1px solid #ced4da;
  /* border-radius: 0px; */
  /* padding: 9.3px; */
  font-size: 13.8px;
  font-family: "Saira", sans-serif !important;
}
.form_group.itm {
  width: 48%;
}

.form_item_fr.flex select {
  border: 1px solid #ced4da;
}
/* .tab_dev .MuiDataGrid-root .MuiDataGrid-window
{
  top:39px !important;
} */
.del span.MuiButton-label {
  width: auto;
  margin-top: 0px;
  padding: 0px;
}
.del button {
  width: 30px;
  margin-top: 0px;
  padding: 5px 18px;
  min-width: auto;
}
.tab_sty button {
  min-width: 140px;
  padding: 0px 4px;
}
button.sep_tab {
  color: #2c4773;
  background: transparent;
  border: none;
  padding: 10px;
  font-weight: 600 !important;
}
.h2_popup .MuiDialogTitle-root {
  padding: 16px 24px 10px 24px;
}
.popup_btn button {
  padding: 10px 20px;
  margin: 0px 3px;
}
.popup_btn {
  padding: 7px 0px;
}
/* .wid_dep .cepJty {
  -webkit-flex: inherit;
} */

.MuiAutocomplete-popper {
  background-color: #fff;
}

.MuiDataGridMenu-root {
  background: #fff;
  padding: 8px 16px;
}

input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

.subdivision_other_hoa_popup{
  /* style={{ backgroundColor: 'white', padding: '5px', borderRadius: '3px' }} */
  background-color: white;
  padding: 5px;
  border-radius: 3px;
}
.MuiDataGridPanelWrapper-root {
  background-color: #fff;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 4%);
}

.OrderInfo-Form{
  min-width: 48% !important;
  margin: 10px !important;
}
.MuiCheckbox-colorSecondary.Mui-checked{
  color: #2c4773 !important;
}
.sidebar {
  position: absolute;
  top: 0px;
  left: -30%;
  height: 100%;
  width: 30%;
  transition: left 0.3s ease-in-out;
}
.sidebar.open {
  left: 0;
}
.sidebar-toggle {
  transition: all 0.3s;
  position: absolute;
  top: 20%;
  right: -15px;
  height: 30px;
  width: 20px;
  border-radius: 0px 50px 50px 0px;
  border: none;
  z-index: 1;
  background-color: #2c4773;
  transition: left 0.3s ease-in-out;
}
.sidebar-toggle.open{
  right:-0px !important
}
.content {
  position: absolute;
  flex-basis: 100% !important;
  width:100% !important;
  transition: left 0.3s ease-in-out;
}
.content.open {
  flex-basis: 70% !important;
  width:70% !important;
  left: 30%;
}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  transition: left 0.3s ease-in-out;
  padding: 3px;
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}